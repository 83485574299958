//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    content: {
      required: true,
      type: Object,
      default() {},
    },
    type: {
      type: String,
      default: 'Section',
    },
    menuVariant: {
      type: String,
      default: null,
    },
    isHomepage: {
      type: Boolean,
      default: false,
    },
    isLandingpage: {
      type: Boolean,
      default: false,
    },
    navigationItems: {
      type: Array, // Adjust the type based on the actual data type of navigationItems
      default: () => [],
    },
    pageId: {
      type: String,
      default: '',
    },
  },
  data() {
    let contentType = ''

    if (this.content) {
      if (!this.content.contentType) {
        contentType = this.content.type
      } else {
        contentType = this.content.contentType
      }

      if (this.content.type === 'Section') {
        contentType = 'SectionTextBox'
      }
    }

    return { contentType }
  },
  mounted() {
    // console.log('Section Nav', this.navigationItems)
    // console.log('props', this.$props)
  },
  computed: {
    id() {
      if (!this.content.title && !this.content.id) return ''

      return this.$slugify(this.content.title || this.content.id, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
      })
    },
  },
  methods: {
    shouldPassNavigationItems(contentType) {
      if (contentType === 'PageHero') {
        return this.navigationItems // Pass navigationItems when contentType is PageHero
      }
      return null
    },
    onCustomEvent(eventName, ...props) {
      this.$emit(eventName, ...props)
    },
  },
}
